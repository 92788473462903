import React, { useRef } from "react";
import Header from "../components/common/Header";
import NutriIntro from "../components/nutricionistas/NutriIntro";
import Whatsapp from "../components/landing/Whatsapp";
import NutriFiltros from "../components/nutricionistas/NutriFiltros";
import DescargarApp from "../components/common/DescargarApp";
import Footer from "../components/common/Footer";
const Nutricionistas = () => {
  const downloadRef = useRef(null);

  const downloadScroll = () => {
    downloadRef.current.scrollIntoView();
  }

  return (
    <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
      <Header downloadScroll={downloadScroll}></Header>
      <NutriIntro></NutriIntro>
      <NutriFiltros></NutriFiltros>
      <DescargarApp refProp={downloadRef}></DescargarApp>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </div>
  );
}

export default Nutricionistas;
