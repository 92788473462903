import React from "react";

const HowItWorks = ({refProp}) => {
  return (
    <section ref={refProp} id="comoFunciona" className="px-4 py-4 lg:-mt-16 relative">
      <div className="container mx-auto max-w-6xl mb-5 md:mb-24">
        <div className="md:hidden mb-10">
          <svg xmlns="http://www.w3.org/2000/svg" width="228" height="21" viewBox="0 0 228 21" fill="none" class="mx-auto">
            <path
              d="M-0.000123565 1.00004L133.242 1.00004C135.799 1.00004 138.202 2.22244 139.708 4.28918L147.374 14.8108C148.879 16.8776 151.282 18.1 153.839 18.1L224.525 18.1"
              stroke="url(#paint0_linear_527_214)"
            />
            <circle cx="225.991" cy="18.0999" r="1.45428" transform="rotate(180 225.991 18.0999)" stroke="#FB7447" />
            <defs>
              <linearGradient id="paint0_linear_527_214" x1="224.525" y1="9.55" x2="-4.50012" y2="9.55" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FB7447" />
                <stop offset="0.36" stop-color="#FB7447" stop-opacity="0.64" />
                <stop offset="1" stop-color="#FB7447" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="hidden md:block absolute left-0 -top-28">
          <img src="/img/como-funciona-hojas.png" alt="hojas" className="w-[250px] h-auto" />
        </div>

        <div className="mb-12">
          <h2 className="text-center text-3xl font-semibold md:text-5xl">¿Cómo funciona?</h2>
        </div>

        <div className="flex flex-col gap-y-5 md:flex-row md:gap-x-5">
          <ProcessStep
            imgSrc="/img/como-funciona-objetivos.svg"
            title="1. Objetivo"
            description="Selecciona el objetivo que mejor se adapte a ti."
          />
          <ProcessStep
            imgSrc="/img/como-funciona-plan.svg"
            title="2. Plan Alimenticio"
            description="Utiliza la IA para generar tu plan o envíanos el plan de tu nutricionista."
          />
          <ProcessStep
            imgSrc="/img/como-funciona-platos.svg"
            title="3. Platos"
            description="Un chef será asignado para preparar tus platos."
          />
          <ProcessStep
            imgSrc="/img/como-funciona-envios.svg"
            title="4. Envios"
            description="Recibe tus comidas diarias en la ubicación que prefieras."
          />
        </div>
      </div>
    </section>
  );
};

const ProcessStep = ({ imgSrc, title, description }) => {
  return (
    <article className="flex gap-3 bg-mc-primary-light hover:bg-mc-primary/10 rounded-xl p-4 md:flex-col md:flex-1 md:text-center md:items-center">
      <div className="flex-none w-20 md:w-auto md:mb-2">
        <img src={imgSrc} alt={title} className="md:h-28" />
      </div>
      <div className="flex-grow">
        <h3 className="text-xl font-semibold md:mb-3">{title}</h3>
        <p className="text-base">{description}</p>
      </div>
    </article>
  );
};

export default HowItWorks;
