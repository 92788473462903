import React from "react";
import PreciosCard from "./PreciosCard";

const Planes = () => {
  return (
    <section id="preciosPlanes" class="px-4 py-4 relative mb-1">
        <div class="container mx-auto max-w-6xl">
            <div class="mb-12 lg:px-20">
                <h2 class="text-2xl font-semibold lg:text-4xl lg:text-center">
                    Nuestros Planes
                </h2>
            </div>
            <div class="container mx-auto grid gap-4 items-start md:grid-cols-2">
                <PreciosCard
                  title="de Ganancia de Masa Muscular"
                  description="Este tipo de plan nutricional se centra en aumentar la ingesta calórica y proteica para promover el crecimiento muscular y el aumento de peso en aquellos que buscan ganar masa muscular."
                  five={{desde: 490, plan: "2.450"}}
                  ten={{desde: 455, plan: "4.550"}}
                  twenty={{desde: 429, plan: "8.580"}}
                  fourty={{desde: 399, plan: "15.960"}}
                />
                <PreciosCard
                  title="de Alimentación Balanceada"
                  description="Este plan nutricional se centra en una dieta equilibrada con alimentos variados de todos los grupos, en las proporciones adecuadas, para garantizar una ingesta adecuada de nutrientes y promover la salud."
                  five={{desde: 465, plan: "2.325"}}
                  ten={{desde: 425, plan: "4.250"}}
                  twenty={{desde: 399, plan: "7.980"}}
                  fourty={{desde: 365, plan: "14.600"}}
                />
                <PreciosCard
                  title="de Pérdida de Peso"
                  description="Estos planes están diseñados para ayudar a las personas a perder peso de manera saludable, a menudo mediante la reducción de calorías, control de porciones y selección de alimentos bajos en calorías pero ricos en nutrientes."
                  five={{desde: 440, plan: "2.200"}}
                  ten={{desde: 399, plan: "3.990"}}
                  twenty={{desde: 380, plan: "7.600"}}
                  fourty={{desde: 345, plan: "13.800"}}
                />
                <PreciosCard
                  title="Basado en Dietas Específicas"
                  description="Estos pueden incluir planes nutricionales diseñados para personas con condiciones médicas específicas, como la diabetes, enfermedades cardíacas, hipertensión, intolerancias alimentarias, alergias, entre otros. Estos planes adaptan la ingesta de alimentos para controlar los síntomas de la enfermedad y promover la salud en general."
                />
            </div>
        </div>
    </section>
  );
};

export default Planes;
