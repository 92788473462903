import React from "react";
import Header from "../components/common/Header";
import EmpresasIntro from "../components/empresas/EmpresasIntro";
import EmpresasInfo from "../components/empresas/EmpresasInfo";
import EmpresasContacto from "../components/empresas/EmpresasContacto";
import Whatsapp from "../components/landing/Whatsapp";
import Footer from "../components/common/Footer";

const Empresas = () => {
  return (
    <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
      <Header></Header>
      <EmpresasIntro></EmpresasIntro>
      <EmpresasInfo></EmpresasInfo>
      {/*<EmpresasContacto></EmpresasContacto>*/}
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </div>
  );
}

export default Empresas;


