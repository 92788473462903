import React, { useRef } from "react";
import Header from "../components/common/Header";
import PlanIAIntro from "../components/planIA/planIAIntro";
import PlanForm from "../components/planIA/planForm";
import Footer from "../components/common/Footer";
import Whatsapp from "../components/landing/Whatsapp";
import DescargarApp from "../components/common/DescargarApp";
const PlanIA = () => {
  const downloadRef = useRef(null);

  const downloadScroll = () => {
    downloadRef.current.scrollIntoView();
  }

  return (
    <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
      <Header downloadScroll={downloadScroll}></Header>
      <PlanIAIntro></PlanIAIntro>
      <div class="hidden lg:block container mx-auto max-w-6xl -mt-5">
        <div class="w-[311px] h-[39px] -ml-12">
          <img src="../img/line-01.svg" alt="line"/>
        </div>
        <img src="../img/intro-02.png" alt="hoja" class="w-[100px] h-auto rotate-180"/>
      </div>
      <PlanForm></PlanForm>
      <DescargarApp refProp={downloadRef}></DescargarApp>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </div>
  );
}

export default PlanIA;
