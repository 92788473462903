import React from 'react';
import Header from "../components/common/Header";

const Privacy = () => {

	return (
        <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
            <Header></Header>
            <div style={{marginTop:'100px', marginLeft:'20px'}}>
                <h1>Política de Privacidad de Mejor Casero</h1>
                <p>Fecha de última actualización: 25 de septiembre de 2023</p>

                <p>Esta Política de Privacidad ("Política") describe cómo Mejor Casero ("nosotros", "nuestro" o "la plataforma") recopila, utiliza y protege la información personal que obtiene de los usuarios de nuestra plataforma y aplicación móvil. Al utilizar Mejor Casero, aceptas los términos de esta Política.</p>
                
                <h3 style={{marginTop:'20px'}}>1. Información que Recopilamos</h3>
                <p>Mejor Casero recopila la siguiente información personal de los usuarios:</p>
                <p>Información de Registro: Cuando te registras en Mejor Casero, recopilamos tu nombre, dirección de correo electrónico y, opcionalmente, información de perfil como tu foto de perfil.</p>
                <p>Información de Uso: Recopilamos información sobre cómo utilizas nuestra plataforma y aplicación, incluyendo las recetas que consultas, los ingredientes que buscas y las valoraciones que das a las recetas.</p>
            
                <h3 style={{marginTop:'20px'}}>2. Uso de la Información</h3>
                <p>Mejor Casero utiliza la información personal recopilada para los siguientes propósitos:</p>
                <p>Mejora de la Experiencia del Usuario: Utilizamos la información para mejorar la calidad de nuestros servicios, personalizar tu experiencia y ofrecerte contenido relevante.</p>

                <h3 style={{marginTop:'20px'}}>3. Compartir Información</h3>           
                <p>Mejor Casero no comparte información personal con terceros sin tu consentimiento expreso, a menos que lo exija la ley o una autoridad competente.</p> 

                <h3 style={{marginTop:'20px'}}>4. Seguridad de la Información</h3>
                <p>Tomamos medidas razonables para proteger la información personal que recopilamos de pérdida, acceso no autorizado, divulgación, alteración y destrucción. Sin embargo, ten en cuenta que ningún sistema de seguridad en línea es completamente seguro.</p>

                <h3 style={{marginTop:'20px'}}>5. Retención de Datos</h3>
                <p>Mantenemos tu información personal durante el tiempo necesario para cumplir con los fines descritos en esta Política, a menos que se requiera una retención más prolongada por ley.</p>

                <h3 style={{marginTop:'20px'}}>6. Tus Derechos</h3>
                <p>Tienes derecho a acceder, corregir, actualizar o eliminar tu información personal. Para ejercer estos derechos, comunícate con nosotros a través de la dirección de correo electrónico <a href="info@mejorcasero.com">info@mejorcasero.com</a>.</p>

                <h3 style={{marginTop:'20px'}}>7. Cambios en esta Política</h3>
                <p>Mejor Casero se reserva el derecho de actualizar esta Política de Privacidad en cualquier momento. Te notificaremos sobre cambios significativos mediante un aviso en nuestra plataforma o por correo electrónico.</p>
                <p>Al utilizar Mejor Casero, aceptas esta Política de Privacidad. Si no estás de acuerdo con los términos de esta Política, te recomendamos que no utilices nuestra plataforma ni nuestra aplicación.</p>
            
                <p style={{marginTop:'30px'}}>Si tienes alguna pregunta o inquietud sobre esta Política de Privacidad, no dudes en contactarnos en <a href="info@mejorcasero.com">info@mejorcasero.com</a>.</p>
                <strong>Fecha de última actualización: 25 de septiembre de 2023</strong>
            </div>
        </div>
	);
};

export default Privacy;