import React from "react";

const PlanIAIntro = () => {
  return (
    <section id="planIntro" class="px-4 py-4">
        <div class="container mx-auto max-w-6xl md:flex items-start relative">
            <div class="md:hidden w-11/12 mx-auto mb-10">
                <img src="../img/plan-ia-intro.png" alt="Plato rico, sano y casero" class="mx-auto" />
            </div>
            <div class="md:flex-1 md:pl-10">
                <div class="hidden md:block py-2">
                    <img src="../img/intro-03.png" alt="hoja" class="absolute w-[160px] -top-4 left-60" />
                    <svg class="ml-[130px] w-full h-auto" viewBox="0 0 635 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M600 116H475.07C472.512 116 470.109 114.778 468.604 112.711L447.896 84.2891C446.391 82.2224 443.988 81 441.43 81L296 81" stroke="url(#paint0_linear_525_212)"/>
                        <circle cx="293" cy="81" r="3.5" stroke="#FB7447"/>
                            <path d="M635 77H510.07C507.512 77 505.109 75.7776 503.604 73.7109L482.896 45.2891C481.391 43.2224 478.988 42 476.43 42H37.3883C34.9334 42 32.6144 40.8729 31.0977 38.9426L6 7" stroke="url(#paint1_linear_525_212)"/>
                            <defs>
                                <linearGradient id="paint0_linear_525_212" x1="296" y1="98.5" x2="600" y2="98.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FB7447"/>
                                    <stop offset="1" stop-color="#FB7447" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                    </svg>
                </div>
                <h2 class="font-extrabold text-mc-base text-2xl leading-tight mb-5 md:hidden">
                    Genera tu plan ahora!
                </h2>
                <h1 class="hidden md:block font-extrabold text-mc-base leading-tight mb-5 text-4xl pr-0">
                    En Mejor Casero utilizamos IA para alcanzar tu plan nutricional ideal
                </h1>
                <p class="text-lg mb-7 leading-tight md:hidden">
                    En Mejor Casero utilizamos IA para alcanzar tu plan nutricional ideal.
                </p>
                <p class="hidden md:block text-lg mb-7 leading-tight">
                    Genera tu plan ahora!
                </p>
            </div>
            <div class="hidden md:block md:flex-1">
                <img src="../img/plan-ia-intro-desktop.png" alt="Plato rico, sano y casero" class="w-full max-w-full -ml-5" />
            </div>

        </div>
    </section>
  );
};

export default PlanIAIntro;
