import React, { useState } from "react";

const PreciosIntro = () => {
  const [slide, setSlide] = useState(0);
  return (
    <section id="preciosIntro" class="px-4 py-4">
        <div class="container mx-auto max-w-6xl md:flex items-start relative">
            <div class="absolute -top-5 right-0 -z-10 md:hidden">
                <img src="../img/intro-02.png" alt="hoja" class="w-[62px] h-auto"/>
            </div>
            <div class="md:hidden w-11/12 mx-auto">
                <img src="../img/precios-intro.png" alt="Plato rico, sano y casero" class="mx-auto"/>
            </div>
            <div class="md:flex-1 md:pl-10">
                <div class="hidden md:block py-2">
                    <img src="../img/intro-03.png" alt="hoja" class="absolute w-[160px] -top-4 left-60"/>
                    <svg class="ml-[130px] w-full h-auto" viewBox="0 0 635 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M600 116H475.07C472.512 116 470.109 114.778 468.604 112.711L447.896 84.2891C446.391 82.2224 443.988 81 441.43 81L296 81" stroke="url(#paint0_linear_525_212)"/>
                        <circle cx="293" cy="81" r="3.5" stroke="#FB7447"/>
                            <path d="M635 77H510.07C507.512 77 505.109 75.7776 503.604 73.7109L482.896 45.2891C481.391 43.2224 478.988 42 476.43 42H37.3883C34.9334 42 32.6144 40.8729 31.0977 38.9426L6 7" stroke="url(#paint1_linear_525_212)"/>
                            <defs>
                                <linearGradient id="paint0_linear_525_212" x1="296" y1="98.5" x2="600" y2="98.5" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FB7447"/>
                                    <stop offset="1" stop-color="#FB7447" stop-opacity="0"/>
                                </linearGradient>
                            </defs>
                    </svg>
                </div>
                {slide === 0 && <div id="introSlide1" class="md:min-h-56">
                    <h2 class="font-extrabold text-mc-base text-3xl leading-tight mt-5 mb-5 md:text-4xl md:pr-0">
                        Elige el plan más adecuado para cumplir tu objetivo!
                    </h2>
                    <p class="text-lg mb-7 leading-tight">
                        En Mejor Casero tenemos una opción para cada tipo de necesidad. Nuestros planes varían según la meta que tengas: pérdida o ganancia de peso, balancear la alimentación o indicaciones médicas específicas.
                    </p>
                </div>}
                {slide === 1 && <div id="introSlide2" class="md:min-h-56">
                    <h2 class="font-extrabold text-mc-base text-3xl leading-tight mt-5 mb-5 md:text-4xl md:pr-0">
                        Comida casera y deliciosa, personalizada para vos!
                    </h2>
                    <p class="text-lg mb-7 leading-tight">
                        ¿Sigues una dieta y no tienes tiempo para cocinar? En Mejor Casero, creamos planes a tu medida, ayudándote a disfrutar de comidas ricas y saludables sin descuidar tus objetivos.
                    </p>
                </div>}
                <div class="hidden md:block">
                    <ul class="flex justify-center gap-3">
                        <li id="introDot1" onClick={() => setSlide(0)} class={`w-4 h-4 bg-mc-base rounded-full cursor-pointer ${slide !== 0 && 'opacity-50'}`}></li>
                        <li id="introDot2" onClick={() => setSlide(1)} class={`w-4 h-4 bg-mc-base rounded-full cursor-pointer ${slide !== 1 && 'opacity-50'}`}></li>
                    </ul>
                </div>
            </div>
            <div class="hidden md:block md:flex-1 pt-10">
                <img src="../img/precios-intro-desktop.png" alt="Plato rico, sano y casero" class="w-full max-w-[100%]"/>
            </div>
        </div>
    </section>
  );
};

export default PreciosIntro;
