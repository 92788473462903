import React from "react";
import Header from "../components/common/Header";
import PreciosIntro from "../components/precios/PreciosIntro";
import Whatsapp from "../components/landing/Whatsapp";
import Footer from "../components/common/Footer";
import Trial from "../components/common/Trial";
import Planes from "../components/precios/Planes";
import IA from "../components/common/IA";
const Nutricionistas = () => {
  return (
    <div class="text-mc-base font-body bg-[#FFFEFA] bg-[url('./img/home-bg-blur.svg')] bg-no-repeat bg-cover bg-left-top">
      <Header></Header>
      <PreciosIntro></PreciosIntro>
      <div class="hidden lg:block container mx-auto max-w-6xl -mt-20">
        <div class="w-[311px] h-[39px] -ml-12">
          <img src="../img/line-01.svg" alt="line"/>
        </div>
        <img src="../img/intro-02.png" alt="hoja" class="w-[100px] h-auto rotate-180"/>
      </div>
      <Trial></Trial>
      <Planes></Planes>
      <div class="mx-auto max-w-6xl container flex justify-center">
        <img src="../img/intro-03.png" alt="hoja" class="max-h-36 h-auto pr-64 relative top-16 z-10"/>
      </div>
      <IA></IA>
      <div style={{height: '70px'}}></div>
      <Footer></Footer>
      <Whatsapp></Whatsapp>
    </div>
  );
}

export default Nutricionistas;


