import React from 'react';

const InNumbers = () => {
  return (
    <section id="enNumeros" className="px-4 py-4">
      <div className="container mx-auto max-w-6xl md:mt-24 mb-16">
        <div className="hidden lg:block mb-10 bg-[url('../src/img/en-numeros-01.svg')] bg-no-repeat bg-bottom">
          <h2 className="text-2xl font-semibold text-center md:text-4xl">Mejor Casero en números</h2>
        </div>

        <div className="grid grid-cols-2 gap-3 md:grid-cols-5">
          <NumberCard count="300" description="platos entregados semanalmente" />
          <NumberCard count="190" description="clientes que viven la experiencia" smallText />
          <NumberCard count="10" description="nutricionistas asociadas" smallText />
          <NumberCard count="5" description="chefs" smallText />
          <NumberCard count="3500" description="kilos de plásticos no volcados al planeta" smallText />
        </div>
        
        <div className="md:hidden">
          <img src="/img/en-numeros-02.png" alt="hoja" />
        </div>
      </div>
    </section>
  );
};

const NumberCard = ({ count, description, smallText = false }) => {
  return (
    <div className="border-4 border-solid rounded-xl border-mc-primary-light p-4 text-center">
      <h3 className={`text-4xl font-bold text-mc-primary/60`}>+<span className="counter">{count}</span></h3>
      <p className={`text-sm ${smallText ? "" : "text-base"}`}>{description}</p>
    </div>
  );
};

export default InNumbers;
