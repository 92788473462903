import React from 'react';

const Trial = () => {
    return (
        <section id="planDegustacion" class="px-4 py-4 relative mb-10">
            <div class="container mx-auto max-w-6xl bg-mc-primary-light rounded-xl text-center p-8 lg:flex lg:text-left gap-10 items-center lg:py-4">
                <div class="mb-2 flex-auto lg:mb-0 lg:pt-4">
                    <img src="../img/precios-degustacion.png" alt="viandas" class="w-full max-w-72 mx-auto lg:max-w-full"/>
                </div>
                <div class="flex-auto">
                    <h2 class="font-bold text-3xl text-mc-primary mb-4 lg:text-4xl">Plan de Degustación</h2>
                    <p class="mb-4 lg:text-lg">Disfruta de nuestras viandas saludables con un precio especial, prueba nuestras deliciosas opciones y accede a una alimentación equilibrada.</p>
                    <a href="https://wa.me/+59898325975?text=Hola,%20me%20interesa%20saber%20más%20acerca%20del%20plan%20degustación.%20Gracias!" class="mc-btn mc-btn-primary">Contactanos</a>
                </div>
            </div>
        </section>
    )
};

export default Trial;


