import React from "react";
import DishCard from "../common/DishCard";

const EmpresasInfo = () => {
  return (
    <section id="empresasInfo" class="px-4 py-4 relative mb-20">
        <div class="container mx-auto max-w-6xl">
            <div class="md:flex items-center mb-10">
                <div class="mb-5 bg-[url('./img/empresas-info-bg.svg')] bg-center bg-contain bg-no-repeat flex-1">
                    <img src="../img/empresas-info.png" alt="platos Mejor Casero" class="max-w-[80%] mx-auto"/>
                </div>
                <div class="text-lg leading-tight flex-1">
                    <p class="mb-4">Nuestro servicio de viandas es práctico y fácil de implementar en tu empresa. <strong>Nos encargamos de todo, desde la preparación hasta la entrega</strong>, para que tú solo te preocupes por disfrutar. Además, trabajamos contigo para personalizar el <strong>menú según las preferencias y necesidades específicas de tu equipo.</strong></p>
                    <p>La salud de tus empleados es nuestra prioridad. Incorporamos alimentos de todos los grupos, asegurando una nutrición equilibrada.</p>
                </div>
            </div>
            <div class="md:flex flex-row-reverse items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5 bg-[url('./img/empresas-info-menu-bg.png')] bg-center bg-contain bg-no-repeat flex-1 p-5">
                    <DishCard
                        imgSrc="/img/nuestros-platos-01.png"
                        title="Pollo arrollado con legumbres"
                        calories="562"
                        protein="45 gr"
                        fats="24 gr"
                        carbs="45 gr"
                    />
                    <DishCard
                        imgSrc="/img/nuestros-platos-02.png"
                        title="Carne al horno con papas"
                        calories="707"
                        protein="37 gr"
                        fats="28 gr"
                        carbs="75 gr"
                    />
                </div>
                <div class="text-lg leading-tight flex-1">
                    <h3 class="font-semibold text-3xl mb-5">Menú Variado y Adaptable</h3>
                    <p class="mb-4">Ofrecemos un menú mensual que <strong>incluye una variedad de opciones para satisfacer todos los gustos</strong>. Cada día presenta una alternativa diferente, para que tus empleados <strong>disfruten de una alimentación rica y variada.</strong></p>
                    <p>Desde <strong>platos tradicionales hasta opciones vegetarianas,</strong> nuestro menú se adapta a las necesidades de tu empresa y a las preferencias de tus trabajadores.</p>
                </div>
            </div>
        </div>
    </section>
  );
};

export default EmpresasInfo;
