import React, { useState } from "react";

const NutriCard = ({ nutriImg, nombre, desc, barrio, consulta, servicios }) => {
  const [open,setOpen] = useState(false);
  return (
    <article class="accordionItem bg-white rounded-xl p-6 shadow-lg">
        <div class="accordionHeader cursor-pointer flex gap-4 md:flex-col md:cursor-default">
            <div class="rounded-md overflow-hidden w-14 h-14 bg-mc-primary md:w-full md:h-full">
                <img src={nutriImg} alt="img perfil" class="w-full h-full object-cover" />
            </div>
            <div class="flex-auto md:mb-2">
                <h3 class="font-semibold text-xl">{nombre}</h3>
                <p class="text-mc-primary text-sm">{desc}</p>
            </div>
            {!open && <div class="iconOpen md:hidden" onClick={() => setOpen(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                                  
            </div>}
            {open && <div class="iconClose md:hidden" onClick={() => setOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
                </svg> 
            </div>}
        </div>
        <div class={`accordionContent ${!open ? 'max-h-0' : 'max-h-100'} overflow-hidden md:max-h-max ${open ? 'open' : null}`}>
            <div class="mb-6 text-sm pt-2 border-t border-t-gray-200 mt-3">
                {barrio ? <p><strong>{barrio}</strong></p> : null}
                <p><strong>Consulta:</strong> {consulta}</p>
                <p><strong>Servicios:</strong> {servicios}</p>
            </div>
            <div>
                <a href={`https://wa.me/+59898325975?text=Hola,%20me%20gustaría%20tener%20una%20consulta%20con%20%2A${nombre.replace(" ","%20").toUpperCase()}%2A%20para%20generar%20mi%20plan,%20muchas%20gracias!`} class="mc-btn mc-btn-primary-outline w-full -outline-offset-2">
                    <span>Agendar consulta</span>
                </a>
            </div>
        </div>
    </article>
  );
};

export default NutriCard;
