import React from 'react';

const IA = () => {
    return (
      <section id="ia" className="px-4 py-4 lg:-mt-16 relative">
        <div className="mx-auto max-w-6xl bg-mc-primary rounded-xl px-5 py-10 md:flex flex-row-reverse items-center md:pl-24 md:pr-16 gap-14 relative mb-6 md:py-16">
          <div className="hidden lg:block absolute -left-12 -top-20">
            <img src="/img/como-funciona-ia-02.svg" alt="IA" />
          </div>
          <div className="mb-12 mx-auto flex-none md:w-72 md:mb-0">
            {/* IA visualization or interactive component here */}
            {/* <!-- <img src="./src/img/como-funciona-ia.png" alt="IA" class="lg:hidden mx-auto"> --> */}
            {/* <!-- <img src="./src/img/como-funciona-ia-desk.png" alt="IA" class="hidden lg:block mx-auto"> --> */}
            <iframe
            title="AIAnimation"
            src="https://lottie.host/embed/721b2588-a45c-4056-b144-72fdb40f10dc/diDscT5Jid.json"
            class="scale-[1.5] max-w-full relative right-2 lg:scale-[2.55] lg:top-6 lg:right-16"
            ></iframe>
          </div>
          <div className="text-center md:text-left flex-auto">
            <h3 className="text-3xl text-white font-semibold mb-8 md:text-[33px]">
            Usamos IA para elaborar planes que se adapten a tus necesidades y objetivos.
            </h3>
            <a href="/plan-ia" target="_self" className="mc-btn mc-btn-secondary">
            Generar nuevo plan
            </a>
          </div>
        </div>
      </section>
    )
};

export default IA;