import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const ApiBaseQuery = {
  baseUrl: 'https://api.mejorcasero.com.uy',
  // baseUrl: "http://localhost:3000",
  prepareHeaders(headers) {
    headers.set("x-api-key", `39aab2fd4bdac3fdc47e6dbd46bf2566201100a456d7ed5814c6631f30c53bb9`);
    return headers;
  },
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery(ApiBaseQuery),
  endpoints(builder) {
    return {
      createClientPlanAI: builder.mutation({
        query: ({ ...body }) => ({
          url: `home/planai`,
          method: "POST",
          body,
        }),
      }),
      updateClientPlanAI: builder.mutation({
        query: ({ ...body }) => ({
          url: `home/planai/${body.id}`,
          method: "PUT",
          body: {
            updatedDishes: body.updatedDishes,
          },
        }),
      }),
    };
  },
});

export const { useCreateClientPlanAIMutation, useUpdateClientPlanAIMutation } = apiSlice;
