import React, { useRef, useEffect, useState } from "react";
import { useCreateClientPlanAIMutation, useUpdateClientPlanAIMutation } from "../../features/api";

const PlanIAIntro = () => {
  const phrases = [
    "Creando el plan perfecto para ti…",
    "Analizando tus preferencias y objetivos…",
    "Encontrando la combinación ideal de alimentos…",
    "Eligiendo los mejores alimentos para ti…",
    "Generando un menú acorde a tu estilo de vida…",
    "Preparando sabores que se alinean con tus metas…",
    "Visualizando tu menú ideal…",
    "Pronto podrás disfrutar de lo que hemos preparado…",
  ];
  const gustos = [
    "Pan",
    "Arroz",
    "Pasta",
    "Polenta",
    "Quinoa",
    "Avena",
    "Lentejas",
    "Porotos",
    "Garbanzos",
    "Copos",
    "Granola",
    "Ravioles",
    "Canelones",
    "Papa",
    "Boniato",
    "Choclo",
    "Vaca",
    "Pollo",
    "Pescado",
    "Cerdo",
    "Leche",
    "Yougurt",
    "Chocolate",
    "Clara de huevo",
    "Huevo",
    "Queso magro",
    "Muzzarella",
    "Manzana",
    "Naranja",
    "Pera",
    "Durazno",
    "Damasco",
    "Pelon",
    "Bergamota",
    "Banana",
    "Higo",
    "Kiwi",
    "Mandarina",
    "Ciruela",
    "Frutilla",
    "Arandanos",
    "Frutos rojos",
    "Ensalada de frutas",
    "Uvas",
    "Pasas",
    "Orejones",
    "Acelga",
    "Alcaucil",
    "Apio",
    "Arvejas",
    "Berro",
    "Brocoli",
    "Brotes",
    "Berenjenas",
    "Calabaza",
    "Cebolla",
    "Coliflor",
    "Chaucha",
    "Endivia",
    "Espinaca",
    "Esparrago",
    "Hongos",
    "Lechuga",
    "Morron",
    "Nabo",
    "Puerro",
    "Palmitos",
    "Pepino",
    "Repollo",
    "Rúcula",
    "Tomate",
    "Zanahoria",
    "Zapallito",
    "Palta",
  ];
  const days = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"];

  const phoneRef = useRef();

  const [step, setStep] = useState(1);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [sex, setSex] = useState(null);
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [age, setAge] = useState(null);
  const [routine, setRoutine] = useState("Estoy sentado por mucho tiempo");
  const [trainingDays, setTrainingDays] = useState(null);
  const [trainingHours, setTrainingHours] = useState(null);
  const [trainingIntensity, setTrainingIntensity] = useState("Leve");
  const [type, setType] = useState("Bajar de peso");
  const [notInclude, setNotInclude] = useState(null);

  const [phrase, setPhrase] = useState(0);

  const [meal, setMeal] = useState("almuerzos");
  const [week, setWeek] = useState(0);

  const [createClientPlanAI, { isLoading, data, isError }] = useCreateClientPlanAIMutation();
  const [updateClientPlanAI] = useUpdateClientPlanAIMutation();

  const [planData, setPlanData] = useState(null);

  useEffect(() => {
    const storedPlan = localStorage.getItem("plan");
    if (storedPlan) setPlanData(JSON.parse(storedPlan));
    const storedName = localStorage.getItem("name");
    if (storedName) setName(JSON.parse(storedName));
    const storedType = localStorage.getItem("type");
    if (storedType) setType(JSON.parse(storedType));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPhrase((phrase + 1) % 8);
    }, 5000);
  }, [phrase]);

  useEffect(() => {
    if (data?.result?.plan) {
      localStorage.setItem("plan", JSON.stringify(data?.result?.plan));
      localStorage.setItem("planId", JSON.stringify(data?.result?.id));
      localStorage.setItem("name", JSON.stringify(name));
      localStorage.setItem("type", JSON.stringify(type));
      setPlanData(data?.result?.plan);
    }
  }, [data]);

  //   useEffect(() => { NO ES NESARIO ME PARECE AHORA NUNCA TIRA ERROR
  //     if (isError) createClientPlanAI({
  //         name,
  //         phone,
  //         sex,
  //         height,
  //         weight,
  //         age,
  //         routine,
  //         trainingDays,
  //         trainingHours,
  //         trainingIntensity,
  //         type,
  //         notInclude,
  //     });
  //   }, [isError,name,phone,sex,height,weight,age,routine,trainingDays,trainingHours,trainingIntensity,type,notInclude,createClientPlanAI]);

  const updateDish = (dish, meal, i, week) => {
    const allowedOptions = {
      0: ["CS0", "CS5", "CS2", "CS3", "CS10", "CS17"],
      1: ["CS1", "CS7", "CS4", "CS6", "CS11", "CS17"],
      2: ["CS8", "CS9", "CS3", "CS12", "CS13", "CS17"],
      3: ["CS5", "CS2", "CS7", "CS6", "CS14", "CS15"],
      4: ["CS4", "CS8", "CS9", "CS16", "CS11", "CS15"],
    };

    const allOptions = {
      CS: ["CS0", "CS1", "CS2", "CS3", "CS4", "CS5", "CS6", "CS7", "CS8", "CS9", "CS10", "CS11", "CS12"],
      CC: ["CC0", "CC1", "CC2", "CC3", "CC4", "CC5", "CC6", "CC7", "CC8"],
      CN: [
        "CN0",
        "CN1",
        "CN2",
        "CN3",
        "CN4",
        "CN5",
        "CN6",
        "CN7",
        "CN8",
        "CN9",
        "CN10",
        "CN11",
        "CN12",
        "CN13",
        "CN14",
        "CN15",
        "CN16",
        "CN17",
      ],
    };

    function getRandomOption(options, exclude) {
      const filteredOptions = options.filter((option) => option !== exclude);
      return filteredOptions[Math.floor(Math.random() * filteredOptions.length)];
    }
    const prefix = dish?.code?.slice(0, 2);
    if (!prefix) return;
    let newDish;
    let newDishCode;
    if (prefix === "CS") {
      newDishCode = getRandomOption(allowedOptions[i], dish.code);
      newDish = dishesDefinition[newDishCode];
    } else {
      newDishCode = getRandomOption(allOptions[prefix], dish.code);
      newDish = dishesDefinition[newDishCode];
    }
    newDish = { ...newDish, id: dish.id };
    let newPlanData = { ...planData };
    newPlanData[meal] = [...newPlanData[meal]];
    newPlanData[meal][week * 5 + i] = newDish;
    setPlanData(newPlanData);
    let planId = localStorage.getItem("planId");
    planId = planId ? JSON.parse(planId) : null;
    if (planId) {
      updateClientPlanAI({ id: planId, updatedDishes: [newDish] });
    }
  };

  return (
    <section id="planFormDatos" class="px-4 py-4 relative mb-1">
      <div class="container mx-auto max-w-6xl">
        {!planData && (
          <div class="container mx-auto shadow-lg px-8 py-12 bg-white rounded-xl mb-14">
            {!isLoading && (
              <div class="text-center mb-10">
                <h2 class="font-bold text-xl">Completa este formulario y visualiza el menú que podrás encargar cada semana</h2>
              </div>
            )}
            {!isLoading && (
              <form id="planFormMenu" action="" method="" onSubmit={(e) => e.preventDefault()}>
                <ul class="flex justify-around md:items-center gap-2 mt-10 mb-12">
                  <li class="flex-1 text-xs">
                    <img src="../img/plan-form-step-1.svg" alt="step1" class="w-full h-auto max-w-16 md:max-w-36 mx-auto" />
                  </li>
                  {step === 1 && (
                    <li class="flex-1 text-xs lg:text-lg">
                      <span class="inline-flex rounded-full bg-mc-primary border border-mc-primary text-white w-8 h-8 justify-center items-center">
                        1
                      </span>
                      <span class="block md:inline-block md:pl-2">Datos personales</span>
                    </li>
                  )}
                  {step === 2 && (
                    <li class="flex-1 text-xs lg:text-lg">
                      <span class="inline-flex rounded-full bg-mc-primary border border-mc-primary text-white w-8 h-8 justify-center items-center">
                        2
                      </span>
                      <span class="block md:inline-block md:pl-2">Rutina y Entrenamiento</span>
                    </li>
                  )}
                </ul>
                {step === 1 && (
                  <div id="planDatosPersonales" class="grid gap-4 lg:grid-cols-3">
                    <label for="nombre" class="block">
                      <span class="block pb-2">Nombre y Apellido</span>
                      <input
                        type="text"
                        id="nombre"
                        name="nombre"
                        placeholder="Ingresar nombre"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </label>
                    <label for="telefono" class="block">
                      <span class="block pb-2">Telefono</span>
                      <input
                        ref={phoneRef}
                        type="tel"
                        id="telefono"
                        name="telefono"
                        placeholder="Ingresar telefono"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        minLength={9}
                        maxLength={9}
                        onChange={(e) => {
                          setPhone(e.target.value);
                          phoneRef.current.reportValidity();
                        }}
                        value={phone}
                      />
                    </label>
                    <label for="sexo" class="block">
                      <span class="block pb-2">¿Cuál es tu sexo de nacimiento?</span>
                      <select
                        name="sexo"
                        id="sexo"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg text-mc-primary/70"
                        required
                        onChange={(e) => setSex(e.target.value)}
                        value={sex}
                      >
                        <option value="" disabled selected>
                          Seleccionar sexo
                        </option>
                        <option value="Hombre">Hombre</option>
                        <option value="Mujer">Mujer</option>
                      </select>
                    </label>
                    <label for="estatura" class="block">
                      <span class="block pb-2">¿Cuánto medís? (cm)</span>
                      <input
                        type="number"
                        id="estatura"
                        name="estatura"
                        placeholder="Estatura"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === ",") e.preventDefault();
                        }}
                        onChange={(e) => setHeight(e.target.value)}
                        value={height}
                      />
                    </label>
                    <label for="peso" class="block">
                      <span class="block pb-2">¿Cuánto pesas? (kg)</span>
                      <input
                        type="number"
                        id="peso"
                        name="peso"
                        placeholder="Peso"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onChange={(e) => setWeight(e.target.value)}
                        value={weight}
                      />
                    </label>
                    <label for="edad" class="block">
                      <span class="block pb-2">¿Qué edad tienes?</span>
                      <input
                        type="number"
                        id="edad"
                        name="edad"
                        placeholder="Edad"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onChange={(e) => setAge(e.target.value)}
                        value={age}
                      />
                    </label>

                    <button
                      class="mc-btn-form mt-4"
                      type="submit"
                      onClick={() => {
                        if (name !== null && phone !== null && sex !== null && height !== null && weight !== null && age !== null)
                          setStep(2);
                      }}
                    >
                      Siguiente
                    </button>
                  </div>
                )}
                {step === 2 && (
                  <div id="planRutinaEntrenamiento" class="grid gap-4 lg:grid-cols-3">
                    <label for="rutina" class="block">
                      <span class="block pb-2">En tu rutina diaria normal:</span>
                      <select
                        name="rutina"
                        id="rutina"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg text-mc-primary/70"
                        required
                        onChange={(e) => setRoutine(e.target.value)}
                        value={routine}
                      >
                        <option value="Estoy sentado por mucho tiempo">Estoy sentado por mucho tiempo</option>
                        <option value="Estoy sentado pero salgo a veces">Estoy sentado pero salgo a veces</option>
                        <option value="Estoy de pie la mayor parte del día">Estoy de pie la mayor parte del día</option>
                        <option value="Hago trabajos manuales y/o físicos">Hago trabajos manuales y/o físicos</option>
                      </select>
                    </label>
                    <label for="dias" class="block">
                      <span class="block pb-2">¿Cuántos días a la semana te ejercitas?</span>
                      <input
                        type="number"
                        id="dias"
                        name="dias"
                        placeholder="Días"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onChange={(e) => setTrainingDays(e.target.value)}
                        value={trainingDays}
                      />
                    </label>
                    <label for="horas" class="block">
                      <span class="block pb-2">¿Cuántas horas al día te ejercitas?</span>
                      <input
                        type="number"
                        id="horas"
                        name="horas"
                        placeholder="Horas"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg"
                        required
                        onChange={(e) => setTrainingHours(e.target.value)}
                        value={trainingHours}
                      />
                    </label>
                    <label for="intensidad" class="block">
                      <span class="block pb-2">Intensidad de mi entrenamiento:</span>
                      <select
                        name="intensidad"
                        id="intensidad"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg text-mc-primary/70"
                        required
                        onChange={(e) => setTrainingIntensity(e.target.value)}
                        value={trainingIntensity}
                      >
                        <option value="Leve">Leve</option>
                        <option value="Moderada">Moderada</option>
                        <option value="Fuerte">Fuerte</option>
                        <option value="No entreno">No entreno</option>
                      </select>
                    </label>
                    <label for="tipoPlan" class="block">
                      <span class="block pb-2">Tipo de plan:</span>
                      <select
                        name="tipoPlan"
                        id="tipoPlan"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg text-mc-primary/70"
                        required
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                      >
                        <option value="Bajar de peso">Bajar de peso</option>
                        <option value="Mejorar mi salud">Mejorar mi salud</option>
                        <option value="Subir de peso">Subir de peso</option>
                      </select>
                    </label>
                    <label for="gustos" class="block">
                      <span class="block pb-2">Evitar:</span>
                      <select
                        name="gustos"
                        id="gustos"
                        class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-primary/70 focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg text-mc-primary/70"
                        onChange={(e) => {
                          if (!notInclude) setNotInclude(e.target.value);
                          else setNotInclude(notInclude + `, ${e.target.value}`);
                        }}
                        value={notInclude}
                      >
                        <option value={notInclude}>{notInclude}</option>
                        {gustos.map((gusto) => (
                          <option value={gusto}>{gusto}</option>
                        ))}
                      </select>
                    </label>

                    <button class="mc-btn-form mt-4 bg-mc-primary-light text-mc-primary" onClick={() => setStep(1)}>
                      {" "}
                      Anterior{" "}
                    </button>
                    <button
                      class="mc-btn-form mt-4"
                      type="submit"
                      onClick={() => {
                        if (
                          name !== null &&
                          phone !== null &&
                          sex !== null &&
                          height !== null &&
                          weight !== null &&
                          age !== null &&
                          routine !== null &&
                          trainingDays !== null &&
                          trainingHours !== null &&
                          trainingIntensity !== null &&
                          type !== null
                        )
                          createClientPlanAI({
                            name,
                            phone,
                            sex,
                            height,
                            weight,
                            age,
                            routine,
                            trainingDays,
                            trainingHours,
                            trainingIntensity,
                            type,
                            notInclude,
                          });
                      }}
                    >
                      Generar Plan IA
                    </button>
                  </div>
                )}
              </form>
            )}
            {isLoading && (
              <div id="planCreando" class="grid gap-4 justify-center py-24">
                <div class="text-center animate-pulse">
                  <img src="../img/plan-creando4.gif" alt="loading" />
                </div>
                <div class="text-center">
                  <h2 class="text-4xl font-bold text-mc-primary mb-4">Aguarda un momento!</h2>
                  <p class="text-lg">{phrases[phrase]}</p>
                </div>
              </div>
            )}
          </div>
        )}
        {planData && (
          <div class="container mx-auto shadow-lg px-8 py-12 bg-white rounded-xl mb-14">
            <div class="text-center mb-10">
              <h2 class="font-bold text-xl">Este es el plan nutricional que creamos en base a tus necesidades</h2>
            </div>
            <div class="flex justify-center items-center mt-10 mb-4 gap-4 w-full">
              <a
                href="#!"
                onClick={() => setMeal("almuerzos")}
                class={meal === "almuerzos" ? "mc-btn w-full mc-btn-secondary" : "mc-btn w-full mc-btn-secondary-outline"}
              >
                Almuerzo
              </a>
              <a
                href="#!"
                onClick={() => setMeal("cenas")}
                class={meal === "cenas" ? "mc-btn w-full mc-btn-secondary" : "mc-btn w-full mc-btn-secondary-outline"}
              >
                Cena
              </a>
            </div>
            <div class="mb-12">
              <ul class="grid grid-cols-2 gap-4 md:grid-cols-4">
                <li class="text-center semanaUno">
                  <a
                    href="#!"
                    onClick={() => setWeek(0)}
                    class={`w-full ${
                      week === 0 ? "bg-mc-primary text-white" : "bg-transparent text-mc-primary"
                    } border border-mc-primary inline-block rounded-md p-1 font-semibold`}
                  >
                    Semana 1
                  </a>
                </li>
                <li class="text-center semanaDos">
                  <a
                    href="#!"
                    onClick={() => setWeek(1)}
                    class={`w-full ${
                      week === 1 ? "bg-mc-primary text-white" : "bg-transparent text-mc-primary"
                    } border border-mc-primary inline-block rounded-md p-1 font-semibold`}
                  >
                    Semana 2
                  </a>
                </li>
                <li class="text-center semanaTres">
                  <a
                    href="#!"
                    onClick={() => setWeek(2)}
                    class={`w-full ${
                      week === 2 ? "bg-mc-primary text-white" : "bg-transparent text-mc-primary"
                    } border border-mc-primary inline-block rounded-md p-1 font-semibold`}
                  >
                    Semana 3
                  </a>
                </li>
                <li class="text-center semanaCuatro">
                  <a
                    href="#!"
                    onClick={() => setWeek(3)}
                    class={`w-full ${
                      week === 3 ? "bg-mc-primary text-white" : "bg-transparent text-mc-primary"
                    } border border-mc-primary inline-block rounded-md p-1 font-semibold`}
                  >
                    Semana 4
                  </a>
                </li>
              </ul>
            </div>
            <div class="grid grid-cols-1 gap-y-10 lg:grid-cols-5 md:gap-3">
              {planData[meal].slice(week * 5, week * 5 + 5).map((dish, i) => (
                <article class="bg-white rounded-lg shadow-lg p-3 text-left relative">
                  <div class="flex gap-2 mb-2 lg:flex-col lg:gap-0">
                    <div class="relative max-w-24 rounded lg:w-full lg:max-w-full">
                      <img
                        src="../img/plan-ia-img-menu.svg"
                        alt="Pollo relleno con papas, garbanzos y huevo"
                        class="mx-auto bg-mc-primary-light rounded lg:bg-white"
                      />
                    </div>
                    <div>
                      <div
                        class="text-sm text-left px-2 bg-mc-secondary-dark text-white rounded max-w-fit
                                lg:absolute -top-2 lg:max-w-full lg:text-center lg:w-[calc(100%-1.5rem)]"
                      >
                        <p class="flex justify-between whitespace-nowrap">
                          <div style={{ whiteSpace: "no-wrap" }}>
                            <strong>{days[i % 5]}</strong>
                          </div>
                          <img
                            src="/img/change-icon.svg"
                            alt="AppStore"
                            style={{ width: "16px" }}
                            class="cursor-pointer mx-2"
                            onClick={() => updateDish(dish, meal, i, week)}
                          />
                        </p>
                      </div>

                      <div class="flex p-1 text-xs gap-1 lg:bg-black/5 lg:rounded-md">
                        <div class="flex-auto flex gap-1 justify-around lg:flex-col lg:flex-1">
                          <p>Kcal: {dish.calories}</p>
                          <p>Prote: {dish.protein} gr</p>
                        </div>
                        <div class="hidden lg:block border-l border-black/10 pr-1"></div>
                        <div class="flex-auto flex gap-1 justify-around lg:flex-col lg:flex-1">
                          <p>Grasas: {dish.fat} gr</p>
                          <p>Carbo: {dish.carbohydrates} gr</p>
                        </div>
                      </div>
                      <div class="mt-2">
                        <h2 class="text-base text-mc-base leading-tight font-medium">{dish.name}</h2>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
            <div class="hidden">
              <a href="#!" class="mc-btn mc-btn-primary w-full">
                Descargar plan nutricional
              </a>
            </div>
            <div class="w-full md:flex justify-center items-center mt-10 gap-4">
              <div>
                <h2 class="font-semibold text-mc-base text-xl">Accede a nuestro menú</h2>
              </div>
              <div>
                <a
                  href={`https://wa.me/+59898325975?text=Hola,%20mi%20nombre%20es%20%2A${name.replace(
                    " ",
                    "%20"
                  )}%2A%20y%20generé%20mi%20plan%20de%20%2A${type
                    .replace(" ", "%20")
                    .toUpperCase()}%2A%20personalizado%20con%20su%20IA,%20me%20gustaría%20que%20lo%20prepararan.%20Gracias!`}
                  class="mc-btn mc-btn-primary"
                >
                  Contactar ahora
                </a>
              </div>
            </div>
            <div class="w-full md:flex justify-center items-center mt-10 gap-4">
              <div>
                <div
                  class="mc-btn mc-btn-primary"
                  onClick={() => {
                    localStorage.removeItem("plan");
                    setPlanData(null);
                  }}
                >
                  Generar nuevo plan
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PlanIAIntro;

const dishesDefinition = {
  CS0: {
    name: "Albóndigas de lomo con arroz y cherrys con parmesano",
    protein: 35,
    carbohydrates: 75,
    fat: 28,
    calories: 610,
    price: 240,
    code: "CS0",
    ingredients: [
      { name: "Lomo", quantity: 200 },
      { name: "Arroz", quantity: 100 },
      { name: "Tomates cherry", quantity: 50 },
      { name: "Queso parmesano", quantity: 20 },
    ],
  },
  CS1: {
    name: "Camarones provenzal al coco con arroz especiado y cherrys",
    protein: 30,
    carbohydrates: 65,
    fat: 18,
    calories: 580,
    price: 250,
    code: "CS1",
    ingredients: [
      { name: "Camarones", quantity: 150 },
      { name: "Leche de coco", quantity: 50 },
      { name: "Arroz", quantity: 100 },
      { name: "Tomates cherry", quantity: 50 },
      { name: "Especias", quantity: 10 },
    ],
  },
  CS2: {
    name: "Pechuga con papas y boniatos y coleslaw",
    protein: 45,
    carbohydrates: 62,
    fat: 12,
    calories: 510,
    price: 200,
    code: "CS2",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 200 },
      { name: "Papas", quantity: 100 },
      { name: "Boniatos", quantity: 100 },
      { name: "Repollo", quantity: 50 },
      { name: "Zanahoria", quantity: 30 },
    ],
  },
  CS3: {
    name: "Muslo a la parrilla con puré de boniato zanahoria y ensalada",
    protein: 42,
    carbohydrates: 68,
    fat: 22,
    calories: 670,
    price: 200,
    code: "CS3",
    ingredients: [
      { name: "Muslo de pollo", quantity: 250 },
      { name: "Boniato", quantity: 150 },
      { name: "Zanahoria", quantity: 50 },
      { name: "Canela", quantity: 5 },
      { name: "Lechuga", quantity: 30 },
      { name: "Tomate", quantity: 40 },
    ],
  },
  CS4: {
    name: "Lomo de cerdo a la mostaza con puré de calabaza y ensalada de pepino y tomate",
    protein: 38,
    carbohydrates: 52,
    fat: 17,
    calories: 560,
    price: 180,
    code: "CS4",
    ingredients: [
      { name: "Lomo de cerdo", quantity: 200 },
      { name: "Mostaza", quantity: 20 },
      { name: "Calabaza", quantity: 150 },
      { name: "Pepino", quantity: 50 },
      { name: "Tomate", quantity: 50 },
    ],
  },
  CS5: {
    name: "Pescado con ajo y perejil con papitas y ensalada de rúcula y cherry",
    protein: 40,
    carbohydrates: 55,
    fat: 11,
    calories: 490,
    price: 210,
    code: "CS5",
    ingredients: [
      { name: "Pescado", quantity: 200 },
      { name: "Ajo", quantity: 10 },
      { name: "Perejil", quantity: 5 },
      { name: "Papas", quantity: 100 },
      { name: "Rúcula", quantity: 30 },
      { name: "Tomates cherry", quantity: 40 },
    ],
  },
  CS6: {
    name: "Bife de vacío a la parrilla con puré y ensalada de lechuga y cebolla",
    protein: 48,
    carbohydrates: 62,
    fat: 27,
    calories: 710,
    price: 210,
    code: "CS6",
    ingredients: [
      { name: "Bife de vacío", quantity: 250 },
      { name: "Papas", quantity: 150 },
      { name: "Lechuga", quantity: 30 },
      { name: "Cebolla", quantity: 40 },
    ],
  },
  CS7: {
    name: "Pastel de carne con ensalada de lechuga, cebolla y palmitos",
    protein: 35,
    carbohydrates: 72,
    fat: 21,
    calories: 620,
    price: 200,
    code: "CS7",
    ingredients: [
      { name: "Carne molida", quantity: 200 },
      { name: "Papas", quantity: 100 },
      { name: "Lechuga", quantity: 30 },
      { name: "Cebolla", quantity: 40 },
      { name: "Palmitos", quantity: 50 },
    ],
  },
  CS8: {
    name: "Hamburguesas de carne picada magra con papitas y zapallo con sésamo y ensalada",
    protein: 36,
    carbohydrates: 70,
    fat: 20,
    calories: 640,
    price: 200,
    code: "CS8",
    ingredients: [
      { name: "Carne picada magra", quantity: 200 },
      { name: "Papas", quantity: 100 },
      { name: "Zapallo", quantity: 100 },
      { name: "Sésamo", quantity: 10 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS9: {
    name: "Tarta de pechuga de pollo con papitas, zapallo y huevo (condimentados con ajo y perejil) y ensalada de tomates",
    protein: 40,
    carbohydrates: 68,
    fat: 14,
    calories: 530,
    price: 210,
    code: "CS9",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 150 },
      { name: "Papas", quantity: 100 },
      { name: "Zapallo", quantity: 100 },
      { name: "Huevo", quantity: 50 },
      { name: "Ajo", quantity: 5 },
      { name: "Perejil", quantity: 5 },
      { name: "Tomate", quantity: 50 },
    ],
  },
  CS10: {
    name: "Chop Suey de carne y arroz",
    protein: 32,
    carbohydrates: 65,
    fat: 12,
    calories: 520,
    price: 200,
    code: "CS10",
    ingredients: [
      { name: "Carne de res", quantity: 120 },
      { name: "Arroz", quantity: 100 },
      { name: "Zanahoria", quantity: 50 },
      { name: "Pimiento", quantity: 50 },
      { name: "Cebolla", quantity: 30 },
      { name: "Salsa de soja", quantity: 10 },
    ],
  },
  CS11: {
    name: "Milanesa de Lomo con puré de papas y ensalada",
    protein: 35,
    carbohydrates: 75,
    fat: 18,
    calories: 620,
    price: 250,
    code: "CS11",
    ingredients: [
      { name: "Lomo de res", quantity: 150 },
      { name: "Huevo", quantity: 30 },
      { name: "Pan rallado", quantity: 50 },
      { name: "Papa", quantity: 150 },
      { name: "Leche", quantity: 20 },
      { name: "Tomate", quantity: 50 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS12: {
    name: "Milanesa de Pollo con papines y ensalada",
    protein: 36,
    carbohydrates: 70,
    fat: 15,
    calories: 580,
    price: 210,
    code: "CS12",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 150 },
      { name: "Huevo", quantity: 30 },
      { name: "Pan rallado", quantity: 50 },
      { name: "Papines", quantity: 150 },
      { name: "Tomate", quantity: 50 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS13: {
    name: "Milanesa de berenjena con ensalada rusa y mix de verdes",
    protein: 18,
    carbohydrates: 60,
    fat: 14,
    calories: 480,
    price: 200,
    code: "CS13",
    ingredients: [
      { name: "Berenjena", quantity: 150 },
      { name: "Huevo", quantity: 30 },
      { name: "Pan rallado", quantity: 50 },
      { name: "Papa", quantity: 100 },
      { name: "Zanahoria", quantity: 50 },
      { name: "Arvejas", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS14: {
    name: "Tortilla de Espinaca con tomate y muzarrella, puré de papa con queso y ensalada",
    protein: 22,
    carbohydrates: 60,
    fat: 18,
    calories: 520,
    price: 210,
    code: "CS14",
    ingredients: [
      { name: "Espinaca", quantity: 100 },
      { name: "Huevo", quantity: 50 },
      { name: "Tomate", quantity: 50 },
      { name: "Mozzarella", quantity: 40 },
      { name: "Papa", quantity: 150 },
      { name: "Queso", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS15: {
    name: "Chop Suey de pollo y arroz y ensaladas",
    protein: 32,
    carbohydrates: 65,
    fat: 12,
    calories: 550,
    price: 200,
    code: "CS15",
    ingredients: [
      { name: "Pollo", quantity: 150 },
      { name: "Arroz", quantity: 100 },
      { name: "Zanahoria", quantity: 40 },
      { name: "Cebolla", quantity: 30 },
      { name: "Morron", quantity: 30 },
      { name: "Salsa de soja", quantity: 20 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS16: {
    name: "Lasaña de carne y verdura con ensaladas",
    protein: 28,
    carbohydrates: 70,
    fat: 20,
    calories: 600,
    price: 250,
    code: "CS16",
    ingredients: [
      { name: "Carne molida", quantity: 100 },
      { name: "Pasta", quantity: 100 },
      { name: "Espinaca", quantity: 50 },
      { name: "Queso ricotta", quantity: 50 },
      { name: "Salsa de tomate", quantity: 50 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CS17: {
    name: "Lasaña de verduras con boniatos y ensalada",
    protein: 20,
    carbohydrates: 65,
    fat: 15,
    calories: 540,
    price: 230,
    code: "CS17",
    ingredients: [
      { name: "Boniato", quantity: 100 },
      { name: "Pasta", quantity: 100 },
      { name: "Zanahoria", quantity: 50 },
      { name: "Berenjena", quantity: 50 },
      { name: "Queso ricotta", quantity: 50 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CN0: {
    name: "Pan pita de Pollo y hummus",
    protein: 32,
    carbohydrates: 50,
    fat: 15,
    calories: 450,
    bigPrice: 284,
    mediumPrice: 243,
    code: "CN0",
    ingredients: [
      { name: "Pan pita", quantity: 80 },
      { name: "Pechuga de pollo", quantity: 100 },
      { name: "Hummus", quantity: 50 },
      { name: "Lechuga", quantity: 20 },
      { name: "Salsa criolla", quantity: 30 },
    ],
  },
  CN1: {
    name: "Pan pita de Carne y hummus",
    protein: 28,
    carbohydrates: 50,
    fat: 18,
    calories: 470,
    bigPrice: 284,
    mediumPrice: 243,
    code: "CN1",
    ingredients: [
      { name: "Pan pita", quantity: 80 },
      { name: "Carne grillada", quantity: 100 },
      { name: "Hummus", quantity: 50 },
      { name: "Lechuga", quantity: 20 },
      { name: "Salsa criolla", quantity: 30 },
    ],
  },
  CN2: {
    name: "Pan pita de Cerdo y hummus",
    protein: 30,
    carbohydrates: 50,
    fat: 20,
    calories: 490,
    bigPrice: 284,
    mediumPrice: 243,
    code: "CN2",
    ingredients: [
      { name: "Pan pita", quantity: 80 },
      { name: "Cerdo grillado", quantity: 100 },
      { name: "Hummus", quantity: 50 },
      { name: "Lechuga", quantity: 20 },
      { name: "Salsa criolla", quantity: 30 },
    ],
  },
  CN3: {
    name: "Tacos de Carne, cebolla y queso con ensalada",
    protein: 24,
    carbohydrates: 40,
    fat: 15,
    calories: 420,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN3",
    ingredients: [
      { name: "Tortilla de trigo", quantity: 50 },
      { name: "Carne", quantity: 80 },
      { name: "Cebolla", quantity: 30 },
      { name: "Queso", quantity: 20 },
      { name: "Tomate", quantity: 30 },
      { name: "Mayonesa con perejil", quantity: 20 },
    ],
  },
  CN4: {
    name: "Tacos de Cerdo, cebolla y queso con ensalada",
    protein: 26,
    carbohydrates: 40,
    fat: 17,
    calories: 440,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN4",
    ingredients: [
      { name: "Tortilla de trigo", quantity: 50 },
      { name: "Cerdo", quantity: 80 },
      { name: "Cebolla", quantity: 30 },
      { name: "Queso", quantity: 20 },
      { name: "Tomate", quantity: 30 },
      { name: "Mayonesa con perejil", quantity: 20 },
    ],
  },
  CN5: {
    name: "Tacos de Pollo, cebolla y queso con ensalada",
    protein: 30,
    carbohydrates: 40,
    fat: 14,
    calories: 410,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN5",
    ingredients: [
      { name: "Tortilla de trigo", quantity: 50 },
      { name: "Pollo", quantity: 80 },
      { name: "Cebolla", quantity: 30 },
      { name: "Queso", quantity: 20 },
      { name: "Tomate", quantity: 30 },
      { name: "Mayonesa con perejil", quantity: 20 },
    ],
  },
  CN6: {
    name: "Cerdo salteado con verduras al wok y pastas",
    protein: 32,
    carbohydrates: 60,
    fat: 20,
    calories: 500,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN6",
    ingredients: [
      { name: "Tallarines", quantity: 100 },
      { name: "Cerdo", quantity: 80 },
      { name: "Cebolla", quantity: 30 },
      { name: "Morrón", quantity: 30 },
      { name: "Zapallito", quantity: 40 },
      { name: "Berenjena", quantity: 40 },
      { name: "Salsa de soja", quantity: 20 },
    ],
  },
  CN7: {
    name: "Wrap de pollo, morron, cebolla y queso con ensalada",
    protein: 30,
    carbohydrates: 45,
    fat: 14,
    calories: 430,
    bigPrice: 259,
    mediumPrice: 220,
    code: "CN7",
    ingredients: [
      { name: "Tortilla de trigo", quantity: 50 },
      { name: "Pollo", quantity: 80 },
      { name: "Morrón", quantity: 30 },
      { name: "Cebolla", quantity: 30 },
      { name: "Queso", quantity: 20 },
      { name: "Lechuga", quantity: 20 },
      { name: "Mayonesa con perejil", quantity: 20 },
    ],
  },
  CN8: {
    name: "Ensalada de Atún",
    protein: 35,
    carbohydrates: 35,
    fat: 15,
    calories: 400,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN8",
    ingredients: [
      { name: "Atún", quantity: 150 },
      { name: "Lechuga", quantity: 30 },
      { name: "Rúcula", quantity: 20 },
      { name: "Zanahoria", quantity: 30 },
      { name: "Aceitunas", quantity: 20 },
      { name: "Maíz crocante", quantity: 20 },
    ],
  },
  CN9: {
    name: "Tortilla de papa con ensalada",
    protein: 18,
    carbohydrates: 60,
    fat: 16,
    calories: 520,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN9",
    ingredients: [
      { name: "Papas", quantity: 200 },
      { name: "Cebolla", quantity: 50 },
      { name: "Huevo", quantity: 100 },
    ],
  },
  CN10: {
    name: "Chop suey de fideos con pollo y verduras",
    protein: 36,
    carbohydrates: 65,
    fat: 14,
    calories: 560,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN10",
    ingredients: [
      { name: "Fideos", quantity: 150 },
      { name: "Suprema de pollo", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Morrón", quantity: 30 },
      { name: "Zapallito", quantity: 30 },
      { name: "Berenjena", quantity: 30 },
      { name: "Salsa de soja", quantity: 20 },
    ],
  },
  CN11: {
    name: "Chop suey de arroz con pollo y verduras",
    protein: 35,
    carbohydrates: 70,
    fat: 12,
    calories: 530,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN11",
    ingredients: [
      { name: "Arroz", quantity: 150 },
      { name: "Suprema de pollo", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Morrón", quantity: 30 },
      { name: "Zapallito", quantity: 30 },
      { name: "Berenjena", quantity: 30 },
      { name: "Salsa de soja", quantity: 20 },
    ],
  },
  CN12: {
    name: "Arroz con Quinoa y Pollo",
    protein: 34,
    carbohydrates: 72,
    fat: 10,
    calories: 500,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN12",
    ingredients: [
      { name: "Arroz", quantity: 100 },
      { name: "Quinoa", quantity: 50 },
      { name: "Suprema de pollo", quantity: 100 },
    ],
  },
  CN13: {
    name: "Arepa de cerdo con ensalada",
    protein: 30,
    carbohydrates: 55,
    fat: 23,
    calories: 590,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN13",
    ingredients: [
      { name: "Arepa", quantity: 80 },
      { name: "Queso", quantity: 50 },
      { name: "Cerdo", quantity: 100 },
      { name: "Mayonesa", quantity: 20 },
      { name: "Perejil", quantity: 5 },
    ],
  },
  CN14: {
    name: "Arepa de pollo con ensalada",
    protein: 35,
    carbohydrates: 54,
    fat: 20,
    calories: 560,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN14",
    ingredients: [
      { name: "Arepa", quantity: 80 },
      { name: "Queso", quantity: 50 },
      { name: "Pechuga de pollo", quantity: 100 },
      { name: "Mayonesa", quantity: 20 },
      { name: "Perejil", quantity: 5 },
    ],
  },
  CN15: {
    name: "Pechuga de Pollo con arroz y vegetales salteados",
    protein: 40,
    carbohydrates: 62,
    fat: 12,
    calories: 520,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN15",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 150 },
      { name: "Arroz", quantity: 100 },
      { name: "Berenjena", quantity: 30 },
      { name: "Zapallito", quantity: 30 },
    ],
  },
  CN16: {
    name: "Salteado de Fideos, con Pollo y Vegetales",
    protein: 36,
    carbohydrates: 68,
    fat: 15,
    calories: 580,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN16",
    ingredients: [
      { name: "Fideos", quantity: 150 },
      { name: "Pechuga de pollo", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Morrón", quantity: 30 },
      { name: "Zapallito", quantity: 30 },
      { name: "Berenjena", quantity: 30 },
      { name: "Salsa de soja", quantity: 20 },
    ],
  },
  CN17: {
    name: "Ensalada de Pollo",
    protein: 32,
    carbohydrates: 38,
    fat: 14,
    calories: 450,
    bigPrice: 259,
    mediumPrice: 226,
    code: "CN17",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 150 },
      { name: "Lechuga", quantity: 30 },
      { name: "Rúcula", quantity: 20 },
      { name: "Zanahoria", quantity: 30 },
      { name: "Aceitunas", quantity: 20 },
      { name: "Maíz crocante", quantity: 20 },
    ],
  },
  CC0: {
    name: "Strogonof de pollo con arroz y ensalada",
    protein: 42,
    carbohydrates: 72,
    fat: 20,
    calories: 620,
    price: 250,
    code: "CC0",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 150 },
      { name: "Arroz", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Crema", quantity: 50 },
      { name: "Champiñones", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC1: {
    name: "Pan de carne con verduras al horno y arroz",
    protein: 45,
    carbohydrates: 65,
    fat: 25,
    calories: 680,
    price: 250,
    code: "CC1",
    ingredients: [
      { name: "Carne molida", quantity: 200 },
      { name: "Zanahoria", quantity: 50 },
      { name: "Papas", quantity: 100 },
      { name: "Arroz", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
    ],
  },
  CC2: {
    name: "Canelones de verdura y carne con ensalada",
    protein: 32,
    carbohydrates: 60,
    fat: 18,
    calories: 540,
    price: 250,
    code: "CC2",
    ingredients: [
      { name: "Carne molida", quantity: 150 },
      { name: "Espinaca", quantity: 50 },
      { name: "Queso ricota", quantity: 50 },
      { name: "Pasta para canelones", quantity: 100 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC3: {
    name: "Zapallitos rellenos de carne con choclo con ensalada",
    protein: 30,
    carbohydrates: 55,
    fat: 15,
    calories: 500,
    price: 250,
    code: "CC3",
    ingredients: [
      { name: "Zapallitos", quantity: 150 },
      { name: "Carne molida", quantity: 100 },
      { name: "Choclo", quantity: 50 },
      { name: "Cebolla", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC4: {
    name: "Tarta de Atun con ensalada",
    protein: 34,
    carbohydrates: 50,
    fat: 14,
    calories: 480,
    price: 250,
    code: "CC4",
    ingredients: [
      { name: "Atún", quantity: 150 },
      { name: "Masa para tarta", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Tomate", quantity: 50 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC5: {
    name: "Tarta de Espinaca y Ricota con ensalada",
    protein: 28,
    carbohydrates: 48,
    fat: 12,
    calories: 460,
    price: 250,
    code: "CC5",
    ingredients: [
      { name: "Espinaca", quantity: 100 },
      { name: "Ricota", quantity: 100 },
      { name: "Masa para tarta", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC6: {
    name: "Tarta pascualina con ensalada",
    protein: 25,
    carbohydrates: 52,
    fat: 13,
    calories: 470,
    price: 250,
    code: "CC6",
    ingredients: [
      { name: "Acelga", quantity: 100 },
      { name: "Huevo", quantity: 50 },
      { name: "Masa para tarta", quantity: 100 },
      { name: "Cebolla", quantity: 30 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC7: {
    name: "Milanesa al horno de Carne",
    protein: 48,
    carbohydrates: 60,
    fat: 28,
    calories: 720,
    price: 250,
    code: "CC7",
    ingredients: [
      { name: "Carne", quantity: 200 },
      { name: "Pan rallado", quantity: 50 },
      { name: "Huevo", quantity: 50 },
      { name: "Papas", quantity: 100 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
  CC8: {
    name: "Milanesa al horno de Pollo",
    protein: 45,
    carbohydrates: 58,
    fat: 22,
    calories: 680,
    price: 250,
    code: "CC8",
    ingredients: [
      { name: "Pechuga de pollo", quantity: 200 },
      { name: "Pan rallado", quantity: 50 },
      { name: "Huevo", quantity: 50 },
      { name: "Papas", quantity: 100 },
      { name: "Lechuga", quantity: 30 },
    ],
  },
};
