import React from "react";
import NutriCard from "./NutriCard";

const NutriFiltros = () => {
  return (
    <section id="nutriFiltros" class="px-4 py-4 relative mb-20">
        <div class="container mx-auto max-w-6xl">
            <div class="mb-12 lg:px-20">
                <h2 class="text-2xl font-semibold lg:text-4xl lg:text-center">
                    Haz tu elección {/*según ubicación geográfica o disponibilidad horaria */}y contacta para comenzar!
                </h2>
            </div>
            {/*<div class="container mx-auto shadow-lg p-6 bg-white rounded-xl mb-14">
                <form action="" method="">
                    <div class="grid gap-4 lg:grid-cols-5">
                        <label for="ubicacion" class="block">
                            <input type="text" id="ubicacion" name="ubicacion" placeholder="Ubicación" class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-base focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg" />
                        </label>
                        <label for="especialidad" class="block">
                            <select name="especialidad" id="especialidad" class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-base focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg">
                                <option value="">Especialidad</option>
                                <option value="">Especialidad 2</option>
                                <option value="">Especialidad 3</option>
                            </select>
                        </label>
                        <label for="tipoConsulta" class="block">
                            <select name="tipoConsulta" id="tipoConsulta" class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-base focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg">
                                <option value="">Tipo de consulta</option>
                                <option value="">Tipo de consulta 2</option>
                                <option value="">Tipo de consulta 3</option>
                            </select>
                        </label>
                        <label for="servicios" class="block">
                            <select name="servicios" id="servicios" class="text-lg px-4 py-3 bg-mc-primary-light/50 border border-mc-primary placeholder-mc-base focus:outline-none focus:border-mc-primary-dark focus:ring-mc-primary-dark w-full rounded-lg">
                                <option value="">Servicios</option>
                                <option value="">Servicios 2</option>
                                <option value="">Servicios 3</option>
                            </select>
                        </label>
                        <button type="submit" class="mc-btn-form">Buscar</button>
                    </div>
                </form>
            </div>*/}
            <div class="container mx-auto grid items-start gap-4 md:grid-cols-2 lg:grid-cols-4">
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/cecilia_trias.jpeg"
                    nombre="Cecilia Trías"
                    desc="Lic. Nutrición Msc. Nutrición clinica y deportiva. Isak 3. Esp en nutrición digestiva"
                    barrio="Carrasco y Palermo"
                    consulta="Virtual / Presencial"
                    servicios="Deporte, digestivo, metabólico"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/MaguiValdomir.JPG"
                    nombre="Magdalena Valdomir"
                    desc="Nutricionista especializada en nutrición deportiva"
                    barrio="Pocitos"
                    consulta="Virtual / Presencial"
                    servicios="Transformo tu alimentación en tu mejor aliado para alcanzar tus objetivos"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/MIGUELINA+AMARO.jpeg"
                    nombre="Miguelina Amaro"
                    desc="Trastornos funcionales digestivos Alimentación vegetariana"
                    barrio={null}
                    consulta="Virtual"
                    servicios="SIBO, IMO, SIFO, intestino colon irritable, dispepsia funcional, enfermedades inflamatorias, gastritis"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/Analia+Alonso+2.png"
                    nombre="Ana Lia Alonso"
                    desc="Nutrición deportiva, cambio de hábitos, problemas digestivos"
                    barrio="Carrasco y Buceo"
                    consulta="Virtual / Presencial"
                    servicios="Lograr cambios de hábitos que se adecuen a los gustos, estilo de vida y salud."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/Lic.+Agustina+Garat.jpeg"
                    nombre="Agustina Garat"
                    desc="Experta en obesidad, TCA y alimentación vegetariana"
                    barrio="Punta carretas"
                    consulta="Virtual / Presencial"
                    servicios="Aprender a alimentarse en calidad y cantidad, sostenerlo en el tiempo sin prohibiciones"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/CeciliaRovella_1.jpeg"
                    nombre="Cecilia Rovella"
                    desc="Especialista en Nutrición Deportiva, Psicología y Psicopatología en la Nutrición"
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especialista en Nutrición Deportiva, Psicología y Psicopatología en la Nutrición"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/manuel_santos.jpeg"
                    nombre="Manuel Santos"
                    desc="Licenciado en Nutrición, especializado en nutrición deportiva y vegetariana"
                    barrio={null}
                    consulta="Virtual"
                    servicios="Nutrición deportiva y vegetariana. Antropometrista ISAK nivel 2."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/bettina_fernandez.jpeg"
                    nombre="Bettina Fernández"
                    desc="Especializada en alimentación vegana, vegetariana y descenso de peso consciente."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especializada en alimentación vegana, vegetariana y descenso de peso consciente."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/valentina_rodriguez.jpeg"
                    nombre="Valentina Rodriguez"
                    desc="Amplia experiencia en sobrepeso y obesidad, Coaching nutricional."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especialista en nutrición deportiva, antropometria ISAK, alimentación sin gluten, trastornos de la conducta alimentaria"
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/gimena_machado.jpeg"
                    nombre="Gimena Machado"
                    desc="Especializada en nutrición, salud hormonal para la mujer y Nutrición materno infantil."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especializada en nutrición, salud hormonal para la mujer y Nutrición materno infantil."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/raquel_katz.jpeg"
                    nombre="Raquel Katz"
                    desc="Especialista en descenso de peso, enfermedades crónicas, Nutrición en el embarazo, lactancia e infancia."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Descenso de peso, enfermedades crónicas, Nutrición en el embarazo, lactancia e infancia."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/daniela_piussi.jpeg"
                    nombre="Lic. Daniela Piussi"
                    desc="Licenciada en nutrición especializada en sobrepeso y obesidad."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Licenciada en nutrición especializada en sobrepeso y obesidad."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/carolina_cardona.jpeg"
                    nombre="Carolina Cardona"
                    desc="Especializada en Sobrepeso y obesidad, Adultos y niños."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especializada en Sobrepeso y obesidad, Adultos y niños."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/valentina_franco.jpeg"
                    nombre="Valentina Franco"
                    desc="Especializada en nutrigenetica."
                    barrio={null}
                    consulta="Virtual"
                    servicios="Especializada en nutrigenetica."
                ></NutriCard>
                <NutriCard
                    nutriImg="https://s3.sa-east-1.amazonaws.com/dev.client-front-end.assests/nutris/CeciliaLavagna_1.jpeg"
                    nombre="Cecilia Lavagna"
                    desc="Especializada en nutrición para el deporte"
                    barrio={null}
                    consulta="Virtual"
                    servicios="Nutricion deportiva"
                ></NutriCard>
            </div>

        </div>
    </section>
  );
};

export default NutriFiltros;

