import React from "react";

const DishCard = ({ imgSrc, title, calories, protein, fats, carbs }) => {
    return (
      <article className="bg-white rounded-2xl shadow-xl p-4 text-center relative">
        <div className="-mt-14 relative">
          <img src={imgSrc} alt={title} className="relative z-10 lg:scale-125" />
          <div className="absolute bottom-4 left-4 z-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="111" height="111" viewBox="0 0 111 111" fill="none">
              <circle cx="55.5" cy="55.5" r="49.5" stroke="#FFD0C0" stroke-width="12" />
            </svg>
          </div>
        </div>
        <div className="my-5">
          <h3 className="text-2xl leading-tight font-medium font-nombrePlatos">{title}</h3>
        </div>
        <div className="bg-[#FFF9F4] flex rounded-lg p-3 text-sm">
          <div className="flex-1">
            <p>Kcal: {calories}</p>
            <p>Prote: {protein}</p>
          </div>
          <div className="w-px bg-mc-base/50"></div>
          <div className="flex-1">
            <p>Grasas: {fats}</p>
            <p>Carbo: {carbs}</p>
          </div>
        </div>
      </article>
    );
};

export default DishCard;